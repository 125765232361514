import React, {ChangeEvent, Component} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/et';

const PREFERENCES_KEY = 'preferences';

interface State {
	journeys: Journey[];
	tripOrigin: string;
	tripDestination: string
	loading: boolean;
	stops: Stop[];
}

interface ActiveProp {
	active?: boolean
}

const AppContainer = styled.div<ActiveProp>`
	font-family: monospace;
	padding: .8rem;
	opacity: ${p => p.active ? 1 : 0.4};
`;

const TripItem = styled.div<ActiveProp>`
	margin: 0.2rem 0;
	padding: 0.2rem;
	border-bottom: 1px solid #edf0f1;
	display: ${p => p.active ? 'initial' : 'none'};
`;

const ListContainer = styled.div`
	display: flex;
	flex-flow: column;
`;

const TimeContainer = styled.div`
	font-size: 1.6rem;
`;

const Title = styled.div`
	font-size: 1.2rem;
	padding: 0 .5rem;
	cursor: pointer;
`;

const CustomSelect = styled.select`
	background: none;
	font-family: monospace;
	font-size: 1.2rem;
	-moz-appearance: none;
	-webkit-appearance: none;
	border: none;
	border-bottom: 1px solid;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.4rem;
`;

class App extends Component<{}, State> {
	componentDidMount(): void {
		moment.locale('ET');

		const preferences = this.loadPreferences();
		const {tripOrigin, tripDestination} = preferences;

		this.updateStops();
		if (tripOrigin && tripDestination) {
			this.updateTable(tripOrigin, tripDestination);
		}

		setInterval(() => this.forceUpdate(), 30 * 1000);
	}

	switchTrips = () => {
		const tripOrigin = this.state.tripDestination;
		const tripDestination = this.state.tripOrigin;

		this.updateTable(tripOrigin, tripDestination);
	};

	updateTable = (tripOrigin: string, tripDestination: string) => {
		this.savePreferences({tripOrigin, tripDestination});
		this.setState({tripOrigin, tripDestination, loading: true});

		this.fetchJourneys(tripOrigin, tripDestination).then(journeys => {
			this.setState({journeys: journeys as Journey[], loading: false});
		});
	};

	updateStops = () => {
		this.fetchStops().then(stops => {
			this.setState({stops});
		});
	};

	fetchStops = () => {
		return fetch('https://api.ridango.com/v2/64/intercity/originstops')
			.then(res => {
				return res.json();
			});
	};

	fetchJourneys = (tripOrigin: string, tripDestination: string) => {
		const body = {
			"date": moment().format('YYYY-MM-DD'),//"2019-02-22",
			"origin_stop_area_id": tripOrigin,
			"destination_stop_area_id": tripDestination,
			"channel": "web"
		};

		const requestInit = {
			method: "PUT",
			body: JSON.stringify(body),
			header: {
				'Content-Type': 'application/json'
			}
		};

		return fetch('https://api.ridango.com/v2/64/intercity/stopareas/trips/direct', requestInit)
			.then(res => {
				return res.json().then((journeyResponse: JourneyResponse) => journeyResponse.journeys);
			});
	};

	renderJourney = (journeys: Journey[]) => {
		if (!journeys) {
			return null;
		}

		return journeys.map(journey => {
			return journey.trips.map(trip => {

				const departureMoment = moment(trip.departure_time);
				const arrivalMoment = moment(trip.arrival_time);

				return <TripItem key={trip.departure_time} active={departureMoment > moment()}>
					<TimeContainer>{departureMoment.format('LT')} -> {arrivalMoment.format('LT')}</TimeContainer>
					<div>{departureMoment.fromNow()}</div>
				</TripItem>
			});
		})
	};

	handleSwitch = () => {
		this.setState({loading: true});
		this.switchTrips();
	};

	setOrigin = (ev: ChangeEvent<HTMLSelectElement>) => {
		const tripOrigin = ev.target.value;
		this.setState({tripOrigin});
		this.updateTable(tripOrigin, this.state.tripDestination);
	};

	setDestination = (ev: ChangeEvent<HTMLSelectElement>) => {
		const tripDestination = ev.target.value;
		this.setState({tripDestination});
		this.updateTable(this.state.tripOrigin, tripDestination);
	};

	savePreferences = (preferences: Preferences) => {
		if (!this.state) {
			return null;
		}

		localStorage.setItem(PREFERENCES_KEY, JSON.stringify(preferences));
	};

	loadPreferences = (): Preferences => {
		const preferences = JSON.parse(localStorage.getItem(PREFERENCES_KEY) || 'null');
		if (preferences && preferences.tripOrigin && preferences.tripDestination) {
			this.setState(preferences);
			return preferences;
		}

		return {};
	};

	render() {
		if (!this.state || !this.state.stops) {
			return null;
		}

		const {journeys, stops, loading, tripOrigin, tripDestination} = this.state;
		return (
			<AppContainer active={!loading}>
				<Header>
					<CustomSelect value={tripOrigin} onChange={this.setOrigin}>
						{stops.map(stop =>
							<option key={stop.stop_area_id} value={stop.stop_area_id}>{stop.stop_name}</option>
						)}
					</CustomSelect>
					<Title onClick={this.handleSwitch}>-></Title>
					<CustomSelect value={tripDestination} onChange={this.setDestination}>
						{stops.map(stop =>
							<option key={stop.stop_area_id} value={stop.stop_area_id}>{stop.stop_name}</option>
						)}
					</CustomSelect>
				</Header>
				<ListContainer>
					{journeys && this.renderJourney(journeys)}
				</ListContainer>
			</AppContainer>
		);
	}
}

export default App;
